function stringToLfLineEnidng(str: string): string {
  const newStr = str;
  if (!newStr.match(/(\r)/)) return newStr;
  if (newStr.match(/(\r\n)/)) {
    return newStr.replace(/(\r\n)/g, '\n');
  }
  return newStr.replace(/(\r)/g, '\n');
}

export default stringToLfLineEnidng;
