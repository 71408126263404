/* eslint-disable arrow-body-style */

interface IObj {
  [index: string]: Array<string>;
}

export default function csvEmptyColumns(str: string): IObj {
  const obj: IObj = {};
  const headers = str.slice(0, str.indexOf('\n')).split(/[;]/);
  const rows = str
    .slice(str.indexOf('\n'))
    .split('\n')
    .filter((item) => item.length);
  headers.forEach((item) => {
    obj[item] = [];
  });
  rows.reduce((prev, curr) => {
    const prevCopy = prev;
    curr.split(';').map((item, index) => {
      const updateHeader = Object.keys(obj)[index];
      const values = prev[updateHeader];

      if (updateHeader && item.trim().length !== 0 && !item.includes('""')) {
        prevCopy[updateHeader] = [...values, item];
      }
      return null;
    });
    return prevCopy;
  }, obj);
  return obj;
}
