






































































































import Vue from 'vue';
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import draggable from 'vuedraggable';
import csvToArray from '@/utils/csvToArray';
import csvEmptyColumns from '@/utils/csvEmptyColumns';
import theLongestArray from '@/utils/theLongestArray';
import stringToLfLineEnding from '@/utils/stringToLfLineEnding';
import IDocsRepository from '@/services/Repositories/docsRepository/types';
import FactoryRepository from '@/services/RepositoryFactory';
import { IOrdersRepository, IOrderData } from '@/services/Repositories/OrdersRepository/types';

const Factory = new FactoryRepository();

interface IColumnsData {
  [index: string]: Array<string>;
}

export default Vue.extend({
  name: 'ImportedOrder',
  components: {
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
    draggable,
  },
  data() {
    return {
      popup: false,
      ordersAmount: 0,
      dataFromFile: [] as any[],
      orderData: {} as IOrderData,
      columnsData: {} as IColumnsData,
      longestArray: 0 as number,
      MANDATORY_FIELDS: [] as string[],
    };
  },
  computed: {
    ...mapState('globals', ['loader']),
    ...mapState('globals', ['file']),
    ordersRepository(): IOrdersRepository {
      return Factory.get('orders') as IOrdersRepository;
    },
    docsRepository(): IDocsRepository {
      return Factory.get('docs') as IDocsRepository;
    },
    clearButton(): boolean {
      const arrays: Array<Array<string>> = Object.values(this.orderData);
      const anyArrayHasValue = arrays.filter((item) => !!item.length);
      return !!anyArrayHasValue.length;
    },
    fieldsAreValid(): any {
      const mandatoryFields: string[][] = [];
      this.MANDATORY_FIELDS.forEach((mandatoryField: string) => {
        const field = this.orderData[mandatoryField];
        if (field !== undefined) {
          mandatoryFields.push(field);
        }
      });
      const columnIsField = mandatoryFields.filter((item) => !!item.length);
      let fieldIsNotEmpty = true;
      if (columnIsField.length > 0) {
        columnIsField.forEach((field: any) => {
          const fieldObject = this.columnsData[field[0]];
          if (fieldObject !== undefined) {
            if (fieldIsNotEmpty) {
              fieldIsNotEmpty = fieldObject.length === this.longestArray;
            }
          }
        });
      }
      return (columnIsField.length === mandatoryFields.length) && fieldIsNotEmpty;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PAGE']),
    ...mapMutations('globals', ['SET_FILE']),
    getKeyTitle(key: any): any {
      let isMandatoryField = false;
      const returnValue = this.$t(`importOrder.fields.${key}`);
      this.MANDATORY_FIELDS.forEach((field: string) => {
        if (key === field) {
          isMandatoryField = true;
        }
      });
      if (isMandatoryField) return `${returnValue}*`;
      return returnValue;
    },
    readFile() {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const {
            target: { result },
          } = event;
          let newResult = typeof result === 'string' ? result : '';
          if (newResult) {
            newResult = stringToLfLineEnding(newResult);
          }
          this.dataFromFile = csvToArray(newResult);
          this.columnsData = csvEmptyColumns(newResult);
          this.longestArray = theLongestArray(this.columnsData);

          // ----
          const header = `${this.dataFromFile.join(';')}\n`;
          let csv = header;
          const rows = newResult
            .slice(newResult.indexOf('\n'))
            .split('\n')
            .filter((item) => item.length);
          rows.forEach((arr) => {
            csv += `${arr}\n`;
          });
          const csvData = new Blob([csv], { type: 'text/csv' });
          this.SET_FILE(csvData);
          // ----
        }
      };
      reader.readAsText(this.file);
    },
    async saveFile() {
      const filesToSave: any = {};
      Object.keys(this.orderData).forEach((key) => {
        const value = this.orderData[key].join(',');
        if (value === '') return;
        filesToSave[key] = value;
      });
      await this.ordersRepository.sendFieldMapping(filesToSave);
      const {
        data: { messages },
      } = await this.ordersRepository.fileImport(this.file);
      const [firstMessage] = messages;
      this.ordersAmount = parseInt(firstMessage.replace(/^\D+/g, ''), 10);
      this.popup = true;
    },
    backToImport() {
      this.$router.replace({ name: 'OrdersImport' });
    },
    async fieldsMapping() {
      const previewsFiedlMapping: any = await this.ordersRepository.fieldMapping();
      const dataInFile = this.dataFromFile;
      Object.keys(previewsFiedlMapping).forEach((key: string) => {
        dataInFile.forEach((singleData: string) => {
          if (singleData === previewsFiedlMapping[key]) {
            this.orderData[key].push(singleData);
          }
        });
      });
    },
    async getFields(): Promise<void> {
      const fields = await this.docsRepository.getOrderFields();
      const fieldsWithArray = fields.reduce((o, key) => Object.assign(o, { [key]: [] }), {});
      this.orderData = fieldsWithArray;
    },
    async clearFields(): Promise<void> {
      const orderItems: any = [];
      Object.values(this.orderData).forEach((item: any) => {
        if (item.length) {
          item.forEach((deepItem: string) => orderItems.push(deepItem));
        }
      });
      await this.getFields();
      this.dataFromFile.push(...orderItems);
    },
    async getMandatoryFields() {
      const apiURL = process.env.VUE_APP_BASE_URL;
      const data = await axios.get(`${apiURL}docs.json`);
      const { properties } = data.data.components.schemas[
        'OrderImportFieldMapping-order_import_field_mapping_context.read'
      ];
      const getMandatoryFields: string[] = [];
      Object.keys(properties).forEach((key: string) => {
        if (properties[key].nullable === undefined || properties[key].nullable === false) {
          getMandatoryFields.push(key);
        }
      });
      this.MANDATORY_FIELDS = getMandatoryFields;
    },
  },
  async created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'OrdersImport' },
    });
    this.SET_PAGE('OrdersImport');
    await this.getFields();
    await this.getMandatoryFields();
    this.readFile();
    await this.fieldsMapping();
  },
  watch: {
    orderData: {
      handler(val) {
        const changed = Object.values(val).filter((item: any) => item.length && item);
        const { icons, actions } = this.$headerService.getIconsAndActions();
        if (changed.length) {
          this.$headerService.setHeaderIcons({ icon: icons.cancel, action: actions.close });
        } else {
          this.$headerService.setHeaderIcons({ icon: icons.back, action: actions.goBack });
        }
      },
      deep: true,
    },
  },
});
