interface IObj {
  [index: string]: Array<string>;
}

export default function theLongestArray(obj: IObj): number {
  let length = 0;

  Object.values(obj).map((item) => {
    if (item.length > length) {
      length = item.length;
    }
    return null;
  });

  return length;
}
